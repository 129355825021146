import { Injectable } from '@angular/core';
import { Config } from '../_models/config.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private innerConfig: Config;
  private initPromise: Promise<Config>;

  constructor(private http: HttpClient) {
  }

  public get config(): Config {
    return this.innerConfig;
  }

  public get init(): Promise<Config> {
    if (this.initPromise === undefined) {
      this.initPromise = this.http
        .get<Config>('../config.json')
        .toPromise()
        .then(config => (this.innerConfig = config));
    }

    return this.initPromise;
  }
}
