import { environment } from '../environments/environment';
import { ConfigService } from './config.service';

export class ServiceBase {
  private baseUrl: string;

  constructor(protected configService: ConfigService, private modulePath: string) {
    this.baseUrl = `${this.configService.config.api.baseUrl}/api${modulePath}`;
  }

  protected getUrl(...endpoints: string[]): string {
    const list: string[] = [];
    endpoints.forEach(el => {
      if (el) {
        list.push(el.replace('/', ''));
      }

      if (!el || !list[list.length - 1]) {
        throw new Error('Endpoint cannot be null or empty');
      }
    });

    return `${this.baseUrl}/${list.join('/')}`;
  }
}
