import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceBase } from './service-base';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class VocService {
  private environment = environment;

  constructor(private http: HttpClient, configService: ConfigService) {
  }

  public getAssemblyVersion() {
    const url = `/api/core${environment.assemblyVersionEndPoint}`;

    return this.http.get(url, { responseType: 'text' });
  } 
}
