import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ServiceBase } from './service-base';
import { Page } from '../_models/page';
import { FormVersion } from '../_models/form-version';
import { SurveyAnswer } from '../_models/survey-answer';
import { Survey } from '../_models/survey';
import { ConfigService } from './config.service';
import { CustomImportMappingItem } from '../_models/custom-import-mapping-item.model';
import { BrandingInfo } from '../_models/brandingInfo';
import { Template } from '../_models/template';
import { ReCaptchaOption } from '../_models/reCaptchaOption';
import { BranchSocialMedia, SocialMedia } from '../_models/branch';
import { FormAnswer } from '../_models/form-answer';

@Injectable({
  providedIn: 'root',
})
export class SurveyService extends ServiceBase {
  private environment = environment;

  constructor(private http: HttpClient, configService: ConfigService) {
    super(configService, configService.config.api.modules.voc.path);
  }

  public getSurvey(code?: string): Observable<Survey> {
    if (code) {
      const params: any = { code };
      return this.http.get<Survey>(this.getUrl(this.environment.clientEndPoint), { params });
    } else {
      return this.http.get<Survey>(this.getUrl(this.environment.clientEndPoint));
    }
  }

  public getBranchSocialMedia(answer: FormAnswer[]): Observable<BranchSocialMedia[]> {
    return this.http.post<BranchSocialMedia[]>(this.getUrl(this.environment.clientEndPoint, this.environment.branchesEndPoint, this.environment.socialMediaEndPoint), answer);
  }
  
  public getCorporateBrandInfoByBranch(branchId?: string, lang?: string): Observable<BrandingInfo> {
    const params: any = {};
    
    if (lang) {
      params.lang = lang;
    }

    return this.http.get<BrandingInfo>(this.getUrl(this.environment.clientEndPoint, this.environment.branchesEndPoint, branchId, this.environment.brandingEndPoint), { params });
  }

  public getCorporateBrandInfo(code?: string, lang?: string): Observable<BrandingInfo> {
    const params: any = {};

    if (code) {
      params.code = code;
    }

    if (lang) {
      params.lang = lang;
    }

    return this.http.get<BrandingInfo>(this.getUrl(this.environment.clientEndPoint, this.environment.surveyEndPoint, this.environment.brandingEndPoint), { params });
  }

  public getCustomImportMappings(customImportProjectConfigurationId: string): Promise<CustomImportMappingItem[]> {
    return this.http
      .get<CustomImportMappingItem[]>(this.getUrl(this.environment.clientEndPoint, `/survey${this.environment.mappingsEndPoint}/${customImportProjectConfigurationId}`))
      .toPromise();
  }

  public getReCaptcha(): Promise<ReCaptchaOption> {
    return this.http.get<ReCaptchaOption>(this.getUrl(this.environment.clientEndPoint, this.environment.reCaptchaEndPoint)).toPromise();
  }

  public getPage(surveyId: string, templateId: string, language: string): Promise<Page> {
    return this.http
      .get<Page>(this.getUrl(this.environment.clientEndPoint, this.environment.surveyEndPoint, surveyId, this.environment.pageEndPoint, templateId, language))
      .toPromise();
  }

  public getForm(formId: string): Promise<FormVersion> {
    return this.http.get<FormVersion>(this.getUrl(this.environment.clientEndPoint, this.environment.formEndPoint, formId, this.environment.latestVersionEndPoint)).toPromise();
  }

  public validateReceipt(surveyAnswer: SurveyAnswer): Promise<any> {
    return this.http.post<string>(this.getUrl(this.environment.clientEndPoint, this.environment.validateReceiptEndPoint), surveyAnswer).toPromise();
  }

  public getMappedReceiptCode(code: string, culture: string): Promise<any> {
    return this.http
      .get<string>(this.getUrl(this.environment.clientEndPoint, this.environment.receiptEndPoint, this.environment.mappingsEndPoint.concat('?receipt=', code, '&lang=', culture)))
      .toPromise();
  }

  public insertSurveyAnswer(surveyAnswer: SurveyAnswer): Promise<any> {
    return this.http.post<string>(this.getUrl(this.environment.clientEndPoint), surveyAnswer).toPromise();
  }

  public saveSurveyAnswer(surveyAnswer: SurveyAnswer, recaptchaToken: string, customImportEntryId?: string): Observable<any> {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      'x-recaptcha-token': recaptchaToken,
    });

    if (customImportEntryId) {
      return this.http.patch<string>(this.getUrl(this.environment.clientEndPoint), surveyAnswer, { params: { code: customImportEntryId }, headers: httpHeaders });
    }

    return this.http.patch<string>(this.getUrl(this.environment.clientEndPoint), surveyAnswer, { headers: httpHeaders });
  }

  public saveSurveyAnswerPageDisplayed(surveyAnswerId: string, pageDisplayed: boolean): Observable<any> {
    return this.http.patch<boolean>(
      this.getUrl(this.environment.clientEndPoint, this.environment.surveyAnswerEndPoint, surveyAnswerId, this.environment.pageDisplayedEndPoint),
      pageDisplayed
    );
  }

  public getSurveyAnswer(surveyAnswerId: string): Observable<SurveyAnswer> {
    return this.http.get<SurveyAnswer>(this.getUrl(this.environment.clientEndPoint, this.environment.surveyAnswerEndPoint, surveyAnswerId));
  }

  public addUnsubscribeRequest(code: string): Promise<any> {
    console.log(this.environment.clientEndPoint);
    console.log(this.environment.unsubscribeEndPoint);
    console.log(code);
    return this.http.post<any>(this.getUrl(this.environment.clientEndPoint, this.environment.unsubscribeEndPoint, '/' + code), null).toPromise();
  }

  public getTemplate(templateId: string): Promise<Template> {
    return this.http.get<Template>(this.getUrl(this.environment.templatesEndPoint, templateId)).toPromise();
  }
}
