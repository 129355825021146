export class BranchSocialMedia {
  id?: string;
  url: string;
  type: SocialMedia;
  branchId: string;
}

export enum SocialMedia {
  None = 0,
  GoogleReviews = 1,
  Facebook = 2,
  TripAdvisor = 3,
  BingReviews = 4,
  FourSquare = 5,
  Yelp = 6,
}
