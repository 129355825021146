import { Injectable } from '@angular/core';
import { SurveyService } from './survey.service';
import { ReCaptchaOption } from '../_models/reCaptchaOption';

@Injectable({
  providedIn: 'root'
})
export class ReCaptchaConfigService {
  private recaptchaOption: ReCaptchaOption;

  constructor(private surveyService: SurveyService) {
  }

  public get config(): ReCaptchaOption {
    return this.recaptchaOption;
  }

  public async init(): Promise<ReCaptchaOption> {
    try {
      const result = await this.surveyService.getReCaptcha();
      this.recaptchaOption = result;
    } catch {
      // No need to cause any error if this isn't loaded.
      this.recaptchaOption = {
        isEnabled: false,
        siteKey: null,
        projectId: null
      };
    }
    return this.recaptchaOption;
  }
}