import { Injectable } from '@angular/core';
import { ServiceBase } from './service-base';
import { ConfigService } from './config.service';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomValidationService {
  private environment = environment;

  constructor(private http: HttpClient) { }

  public validate(url: string, code?: string): Promise<any> {
    let slash = '/';

    if (url.substr(url.length - 1) === '/') {
      slash = '';
    }

    if (code) {
      return this.http.get<any>(`${url}${slash}${code}`).toPromise();
    }

    return this.http.get<any>(`${url}`).toPromise();
  }
}
