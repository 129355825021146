import { Injectable } from '@angular/core';
import { SurveyService } from './survey.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private languageRegex = /((\w+)-?\w*)/;
  private supportedLanguages: string[]; // = ['en', 'fr', 'en-CA', 'EN', 'FR'];

  /**
   *
   */
  constructor(private surveyService: SurveyService) {}

  public loadSupportedLanguages(supportedLanguages: string): void {
    this.supportedLanguages = supportedLanguages.split(',');
  }

  public getLanguage(languages: readonly string[]): string {
    return this.getSpecificLanguage(languages);
  }

  private getSpecificLanguage(languages: readonly string[]): string {
    var generalLanguages = this.getGeneralLanguages(languages);
    var specificLanguage = undefined;

    // try {
    //   var BreakException = {};

    //   this.supportedLanguages.forEach(lang => {
    //     var languageToLookUp = this.languageRegex.exec(lang).groups.specific;

    //     generalLanguages.forEach(gLang => {
    //       var specificLanguage = this.languageRegex.exec(gLang).groups.specific;
    //       if (specificLanguage === languageToLookUp) {
    //         specificLanguage = this.languageRegex.exec(gLang).groups.specific;
    //         throw BreakException;
    //       }
    //     });
    //   });
    // } catch (BreakException) {}

    this.supportedLanguages.some((lang) => {
      var languageToLookUp = this.languageRegex.exec(lang)[1];

      return generalLanguages.some((gLang) => {
        var specificLanguage = this.languageRegex.exec(gLang)[1];
        if (specificLanguage === languageToLookUp) {
          specificLanguage = this.languageRegex.exec(gLang)[1];
          return true;
        }

        return false;
      });
    });

    if (specificLanguage === undefined) {
      specificLanguage = generalLanguages[0];

      generalLanguages.forEach((gLang) => {
        var languageToLookUp = this.languageRegex.exec(gLang);
        if (languageToLookUp[2] === languageToLookUp[1]) {
          specificLanguage = gLang;
        }
      });
    }

    return specificLanguage.toLowerCase();
  }

  private getGeneralLanguages(languages: readonly string[]): string[] {
    var generalLanguages = [];

    if (languages) {
      for (var i = 0; i < languages.length; i++) {
        var generalLanguageToLookUp = this.languageRegex.exec(languages[i])[2];

        this.supportedLanguages.forEach((sLang) => {
          var groups = this.languageRegex.exec(sLang);
          if (groups[2] === generalLanguageToLookUp) {
            generalLanguages.push(sLang.toLowerCase());
          }
        });

        if (generalLanguages.length > 0) {
          break;
        }
      }
    }
    return generalLanguages;
  }
}
