import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { LogLevel } from '../_models/config.model';

export enum LogType {
  Info = 0,
  Warning,
  Error,
  Success
}

@Injectable({
  providedIn: 'root'
})
export class ConsoleLoggerService {
  private infoColor = '#1f69e0';
  private errorColor = '#d32f19';
  private warningColor = '#ffcc00';
  private successColor = '#91c40f';

  constructor(private configService: ConfigService) {}

  public info(msg: string, from?: string, obj?: any) {
    this.log(msg, from, obj, LogType.Info);
  }

  public success(msg: string, from?: string, obj?: any) {
    this.log(msg, from, obj, LogType.Success);
  }

  public warning(msg: string, from?: string, obj?: any) {
    this.log(msg, from, obj, LogType.Warning);
  }

  public error(obj: any, from?: string, msg?: string): void {
    this.log(msg, from, obj, LogType.Error);
  }

  public log(msg: string, from: string, obj: any, type: LogType) {
    if (this.configService.config.logging.level === LogLevel.none) {
      return;
    }

    const infoStyle = `color: ${this.infoColor}`;
    const infoLabelStyle = `color: white; background-color: ${this.infoColor}`;

    const errorLabel = `color: ${this.errorColor}`;
    const errorLabelStyle = `color: white; background-color: ${this.errorColor}`;

    const successLabel = `color: ${this.successColor}`;
    const successLabelStyle = `color: white; background-color: ${this.successColor}`;

    const warningLabel = `color: ${this.warningColor}`;
    const warningLabelStyle = `color: white; background-color: ${this.warningColor}`;

    if (obj === undefined) {
      obj = '';
    }

    if (from === undefined) {
      from = 'GENERAL';
    }

    if (msg === undefined) {
      msg = '';
    }

    switch (type) {
      case LogType.Info:
        if (this.configService.config.logging.level < LogLevel.verbose) {
          return;
        }

        console.log(`%c INFO ` + `%c FROM: ${from} ` + `%c ${msg}`, infoLabelStyle, infoLabelStyle, infoStyle, obj);
        break;

      case LogType.Error:
        console.log(`%c ERROR ` + `%c FROM: ${from} ` + `%c ${msg}`, errorLabelStyle, errorLabelStyle, errorLabel, obj);
        break;

      case LogType.Success:
        console.log(`%c SUCCESS ` + `%c FROM: ${from} ` + `%c ${msg}`, successLabelStyle, successLabelStyle, successLabel, obj);
        break;

      case LogType.Warning:
        if (this.configService.config.logging.level < LogLevel.warning) {
          return;
        }

        console.log(`%c WARNING ` + `%c FROM: ${from} ` + `%c ${msg}`, warningLabelStyle, warningLabelStyle, warningLabel, obj);
        break;

      default:
        console.log(msg, obj);
        break;
    }
  }
}
