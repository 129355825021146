import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class LoadingSpinnerComponent implements OnChanges {
  // tslint:disable-next-line: no-input-rename
  @Input('show-spinner') showSpinnerInput: boolean;
  @Input('loading-text') loadingText: string;

  public showSpinner: boolean;

  constructor() {
    this.showSpinner = this.showSpinnerInput;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.logger.info('Changes', 'SpinnerComponent', changes);

    if(changes.showSpinnerInput){
      this.showSpinner = changes.showSpinnerInput.currentValue;
    }
  }
}
