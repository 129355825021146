import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { SharedService } from './_services/shared.service';
import { ConsoleLoggerService } from './_services/console-logger.service';
import { VocService } from './_services/voc.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'voc-client';
  public showLoaderOverlay: boolean;
  public overlayHeight: string;

  constructor(
    private sharedService: SharedService,
    private logger: ConsoleLoggerService,
    private changeDetector: ChangeDetectorRef,
    private vocService: VocService,
    private meta: Meta
  ) {}

  ngAfterViewInit(): void {
    this.sharedService.showLoader.subscribe((show) => {
      this.showLoaderOverlay = show;
      this.changeDetector.detectChanges();
    });

    this.sharedService.pageHeight.subscribe((height) => {
      this.overlayHeight = `${height}px`;
      this.changeDetector.detectChanges();
    });

    this.vocService.getAssemblyVersion().subscribe((data) => {
      this.meta.addTag({ name: 'version', content: data });
    });
  }
}
