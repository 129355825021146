import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConsoleLoggerService } from './console-logger.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private showLoaderBahavior: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private pageHeightBahavior: BehaviorSubject<number> = new BehaviorSubject(0);
  public showLoader: Observable<boolean> = this.showLoaderBahavior.asObservable();
  public pageHeight: Observable<number> = this.pageHeightBahavior.asObservable();

  constructor(private logger: ConsoleLoggerService) {}

  public setShowLoader(show: boolean): void {
    this.showLoaderBahavior.next(show);
  }

  public setPageHeight(height: number): void {
    this.pageHeightBahavior.next(height);
  }
}
