import { Component, OnInit, ViewChild } from '@angular/core';
import { RecaptchaComponent as NgRecaptchaComponent, RecaptchaSettings } from 'ng-recaptcha';
import { Observable, Subject } from 'rxjs';
import { ReCaptchaOption } from '../_models/reCaptchaOption';

@Component({
  selector: 'app-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.css'],
})
export class RecaptchaComponent implements OnInit {
  @ViewChild('captchaRef') captchaRef: NgRecaptchaComponent;
  private tokenBehavior = new Subject<string>();
  public isEnabled: boolean;
  public model: RecaptchaSettings;

  /**
   * Subscribe to the Observable to receive the recaptcha token after the challenge is issued.
   * After subscribing, call execute() to start the challenge.
   * Check against null but empty string represent a disabled recaptcha.
   */
  public token: Observable<string> = this.tokenBehavior.asObservable();

  constructor(recaptchaOption: ReCaptchaOption) {
    this.isEnabled = recaptchaOption.isEnabled;

    this.model = {
      size: 'invisible',
      siteKey: recaptchaOption.siteKey,
    };
  }

  ngOnInit() {}

  /**
   * Automatically called by recaptcha once the challenge have passed.
   */
  public resolved(captchaResponse: string) {
    // Automatically called once recaptcha resolved
    this.tokenBehavior.next(captchaResponse);
  }

  /**
   * Subscribe to the token observable before calling execute()
   */
  public execute(): void {
    if (this.isEnabled) {
      this.captchaRef.execute();
    } else {
      // If it's disabled, recaptcha will never resolve.
      // Set token to empty string to communicate that it's been processed.
      this.tokenBehavior.next('');
    }
  }
}
