import { Component, OnInit, ElementRef, AfterViewInit, NgZone, OnDestroy, ChangeDetectorRef, KeyValueDiffers, ViewChild, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SurveyService } from '../_services/survey.service';
import { Location } from '@angular/common';
import { Survey } from '../_models/survey';
import { Page } from '../_models/page';
import * as SurveyAngular from 'survey-angular';
import * as SurveyKo from 'survey-knockout';
import * as widgets from 'surveyjs-widgets';
import { FlowContent } from '../_models/flow-content';
import { ConsoleLoggerService } from '../_services/console-logger.service';
import { SurveyAnswer, SurveyState } from '../_models/survey-answer';
import { FormAnswer } from '../_models/form-answer';
import { LanguageService } from '../_services/language.service';
import { SessionStorageService } from 'angular-web-storage';
import * as showdown from 'showdown';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { FormVersion } from '../_models/form-version';
import { Subject, Subscription, Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from '../_services/shared.service';
import { CustomValidationService } from '../_services/custom-validation.service';
import { TranslateService } from '@ngx-translate/core';
import { CrossForm } from '../_models/cross-form';
import { CustomImportMappingItem } from '../_models/custom-import-mapping-item.model';
import { BrandingInfo } from '../_models/brandingInfo';
import { HttpClient, HttpParams } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { RecaptchaComponent } from '../recaptcha/recaptcha.component';
import { SocialMedia } from '../_models/branch';
import { Meta } from '@angular/platform-browser';
import anime from 'node_modules/animejs/lib/anime.es.js';
import { Theme, ThemeType } from '../_models/theme';
import * as moment from 'moment';

declare var $: any;
declare var surveyjsslidersix: any;

widgets.jqueryuidatepicker(SurveyAngular);
widgets.select2(SurveyAngular);
widgets.inputmask(SurveyAngular);
widgets.jquerybarrating(SurveyAngular);
widgets.emotionsratings(SurveyAngular);
widgets.nouislider(SurveyAngular);
widgets.sortablejs(SurveyAngular);
widgets.prettycheckbox(SurveyAngular);
surveyjsslidersix(SurveyAngular);

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('surveyContainer') surveyContainer: ElementRef;
  @ViewChild(RecaptchaComponent) recaptchaComponent: RecaptchaComponent;

  private readonly loggerFrom: string = 'SurveyComponent';

  private savingCancellationToken = new Subject();
  private cancellationToken = new Subject();
  private validationCancellationToken = new Subject();
  private recaptchaSub: Subscription = Subscription.EMPTY;
  private loading: Subscription;
  private preventClick: boolean;

  private currentStep: any = 0;
  private loadingStep: any = -1;
  private formLoadedIndex: number;
  private survey: Survey;
  private brandingInfo: BrandingInfo;
  private formIds: string[];
  private firstLoadFormIds: string[];

  private validMarvelTags = [
    'image',
    'text',
    'form',
    'body',
    'html',
    'imageSrc',
    'alert',
    'subscription',
    'organization',
    'branch',
    'import',
    'queryString',
    'query-string',
    'query-string-restriction',
    'branch-social-media',
    'voc',
    'cookie',
  ];
  private regex = new RegExp('<!--\\s?marvel(.((' + this.validMarvelTags.join('|') + ')))?::((\\w+(-\\w+)*?\\.?)+)(\\(.*?\\))?\\s?-->');
  private globalRegex = new RegExp(this.regex, 'g');
  private attrRegex = /(?:(\w+)=\"((?:\w+(?:[-\s:|.\/\\]\w+)?)+)\")+[,\s?]?/gm;
  private attrRegexUrl = /([\w-]+)=("(?:\\"|[^"])*")/gm;

  private language: string;
  private languageKey = 'languageKey';
  private languageQuestion = 'q_culturecode';
  private activatedStyle = 'bootstrap';
  private data: FormAnswer[] = [];
  private flowContent: FlowContent;
  private surveyAnswerState: { page: number; answerId: string };
  private xForms: CrossForm[] = [];
  public loadingText: string;
  public isLoading: boolean;
  private paramMap: ParamMap;
  private recaptchaToken: string;
  private paramBranchId: string;

  private setUnsubscribeEvent: boolean;
  private customImportMappings: CustomImportMappingItem[] = [];
  private receiptMappings: any[];

  private socialMediaPageDisplayed = false;
  private surveyCode = null;
  private usingFuzzyLanguage = false;

  private cookiePopupText = '';
  private cookiePopupAcceptButton = '';
  private cookiePopupRefuserButton = '';
  private cookiePolicyText = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private self: ElementRef,
    private surveyService: SurveyService,
    private location: Location,
    private logger: ConsoleLoggerService,
    private languageService: LanguageService,
    private session: SessionStorageService,
    private titleService: Title,
    private zone: NgZone,
    private sharedService: SharedService,
    private customValidationService: CustomValidationService,
    private translateService: TranslateService,
    private meta: Meta
  ) {
    this.preventClick = true;
    this.self = self;
    this.formIds = [];
    this.firstLoadFormIds = [];
    this.surveyAnswerState = {
      page: 0,
      answerId: '00000000-0000-0000-0000-000000000000',
    };
  }

  ngOnInit() {
    SurveyAngular.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
      const location = window.location;
      const referer = `${location.protocol}//${location.host}${location.pathname}`;

      options.request.setRequestHeader('x-hexa-referer', referer);
    };

    SurveyKo.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
      const location = window.location;
      const referer = `${location.protocol}//${location.host}${location.pathname}`;

      options.request.setRequestHeader('x-hexa-referer', referer);
    };

    this.addCustomValidators();

    this.route.queryParamMap.subscribe((paramMap) => {
      this.paramMap = paramMap;
    });

    this.route.data.subscribe((observer) => {
      this.formLoadedIndex = 0;
      const resolverReturn: any = observer;

      this.logger.info('Resolver result', this.loggerFrom, resolverReturn);

      if (resolverReturn.survey.errorResult) {
        const errorObj = resolverReturn.survey.errorResult;

        this.survey = resolverReturn.survey.errorResult.error;
        this.flowContent = JSON.parse(this.survey.flow.flowJson);
        this.loadTheme(this.survey.isSaas, this.survey.themeJson);

        this.handleError(errorObj);
      } else {
        this.survey = resolverReturn.survey;
        this.loadTheme(this.survey.isSaas, this.survey.themeJson);

        this.languageService.loadSupportedLanguages(this.survey.supportedLanguages);

        this.route.queryParams.subscribe((param) => {
          if (param.branchId) {
            this.paramBranchId = param.branchId;
          }

          if (param.lang) {
            this.language = this.languageService.getLanguage([param.lang]);
          } else {
            this.usingFuzzyLanguage = true;
            const languageSession = this.session.get(this.languageKey);
            if (languageSession) {
              this.language = languageSession;
            } else {
              this.language = this.languageService.getLanguage(window.navigator.languages);
            }
          }

          this.changeLanguage(this.language);

          if (param.code) {
            this.surveyCode = param.code;
          }
        });

        this.logger.info('Survey Loaded: ', this.loggerFrom, this.survey);

        if (this.survey.customImportEntryId) {
          this.surveyService.getCustomImportMappings(this.survey.customImportconfigurationId).then((mappings) => {
            this.logger.info('CustomImport Mappings', this.loggerFrom, mappings);

            this.customImportMappings = mappings;
          });
        }

        this.flowContent = JSON.parse(this.survey.flow.flowJson);
        if (this.usingFuzzyLanguage || !this.survey.supportedLanguages.includes(this.language)) {
          this.language = this.languageService.getLanguage([this.survey.defaultLanguage]);
          this.changeLanguage(this.language);
          this.usingFuzzyLanguage = false;
        }

        this.session.set(this.languageKey, this.language);
        // The first page loaded should be as auto navigation, and not save, only after a change
        this.navigate(true);
      }
    });

    this.translateService.get('cookie_popup').subscribe((translation) => {
      this.cookiePopupText = translation;
    });
    this.translateService.get('cookie_policy_popup').subscribe((translation) => {
      this.cookiePolicyText = translation;
    });
    this.translateService.get('cookie_popup_accept').subscribe((translation) => {
      this.cookiePopupAcceptButton = translation;
    });
    this.translateService.get('cookie_popup_refuse').subscribe((translation) => {
      this.cookiePopupRefuserButton = translation;
    });
  }

  ngAfterViewInit(): void {
    this.setEventHandler();
  }

  ngOnDestroy() {
    this.cancellationToken.next();
    this.cancellationToken.complete();
    this.savingCancellationToken.next();
    this.savingCancellationToken.complete();
    this.validationCancellationToken.next();
    this.validationCancellationToken.complete();
  }

  private changeLanguage(lang: string) {
    if ($ && $.datepicker) {
      $.datepicker.setDefaults({
        dateFormat: 'yy-mm-dd',
      });
      $.datepicker.setDefaults($.datepicker.regional[lang]);
    }

    // Setting the current language to be user
    this.translateService.use(lang);
    this.translateService.get('loading').subscribe((text) => {
      this.loadingText = text;
    });
  }

  private loadTheme(isSaas: boolean, themeJson: string): void {
    let theme: Theme;

    if (themeJson) {
      theme = JSON.parse(themeJson);
    }

    if (isSaas) {
      this.activatedStyle = 'bootstrapmaterial';
      document.getElementById('surveyjs-theme').setAttribute('href', '/css/bootstrap-material-design.min.css');
    } else {
      switch (theme?.type) {
        case ThemeType.bootstrap_material_design:
          this.activatedStyle = 'bootstrapmaterial';
          document.getElementById('surveyjs-theme').setAttribute('href', '/css/bootstrap-material-design.min.css');
          break;
        case ThemeType.default:
        default:
          this.activatedStyle = 'bootstrap';
          document.getElementById('surveyjs-theme').setAttribute('href', '/css/bootstrap.min.css');
          break;
      }
    }
  }

  private addCustomValidators() {
    var surveyComponent = this;

    SurveyAngular.FunctionFactory.Instance.register('padLeft', function (params) {
      if (params && params.length) {
        const pad = (n, width, z) => {
          z = z || '0';
          n = n + '';
          return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        };

        return pad(params[0], params.length > 1 ? params[1] : null, params.length > 2 ? params[2] : null);
      }

      return '';
    });

    SurveyAngular.FunctionFactory.Instance.register(
      'serverSide',
      function (params) {
        const self: any = this;

        if (params.length == 2 && params[1]) {
          const url = params[0];
          const value = params[1];

          surveyComponent.validationCancellationToken.next();
          surveyComponent.validationCancellationToken.complete();
          surveyComponent.validationCancellationToken = new Subject();

          surveyComponent.http
            .get(url.concat(`?value=${encodeURIComponent(value)}&lang=${surveyComponent.language}`))
            .pipe(takeUntil(surveyComponent.validationCancellationToken))
            .subscribe((result) => {
              self.returnResult(result);
            });
        } else {
          const url = params[0].toString();
          let queryParams = `?lang=${surveyComponent.language}`;

          params.slice(1).forEach((p) => {
            queryParams = queryParams.concat(`&${p.split('=')[0]}=${encodeURIComponent(p.split('=')[1])}`);
          });

          surveyComponent.validationCancellationToken.next();
          surveyComponent.validationCancellationToken.complete();
          surveyComponent.validationCancellationToken = new Subject();

          surveyComponent.http
            .get(url.concat(queryParams))
            .pipe(takeUntil(surveyComponent.validationCancellationToken))
            .subscribe((result: any) => {
              self.returnResult(result.result);
            });
        }
      },
      true
    );
  }

  private setEventHandler() {
    $(document).ready(() => {
      const location = window.location;

      $('a[data-hexa-lang]').each(function () {
        // Construct link to navigate to different language.
        // The link will preserve query params.
        const lang = $(this).data('hexa-lang');

        const searchParams = new URLSearchParams(location.search);
        searchParams.set('lang', lang);
        const url = `${location.protocol}//${location.host}${location.pathname}?${searchParams.toString()}`;

        $(this).attr('href', `${url}`);
      });

      $('#landing-btn').click(() => {
        this.navigate();
      });

      if (this.setUnsubscribeEvent) {
        this.logger.info('Change unsubscribe button', this.loggerFrom, $('#btn-unsubscribe'));

        $('#btn-unsubscribe').click(() => {
          const queryParam: any = this.route.snapshot.queryParamMap;

          this.logger.info('Unsubscribe request', this.loggerFrom, queryParam.params.code);

          this.surveyService.addUnsubscribeRequest(queryParam.params.code).then((r) => {
            this.logger.info('Unsubscribed', this.loggerFrom, r);
            $('.unsubscibe-to-hide').addClass('hidden');
            $('.unsubscibe-to-show').removeClass('hidden');
          });
        });
      }

      $('select.form-control').change(() => {
        $('select.form-control').blur();
      });
    });
  }

  private navigate(autoNavigation: boolean = false): Promise<void> {
    this.isLoading = true;
    let path = this.survey.location;
    const skipFirstSegment = this.survey.location !== '';
    const segmentIndex = skipFirstSegment ? 1 : 0;
    let queryParams;

    if (this.route.snapshot.url[segmentIndex] && this.route.snapshot.url[segmentIndex].path !== this.survey.location) {
      path = this.route.snapshot.url[segmentIndex].path;
      queryParams = this.route.snapshot.queryParamMap;
    }

    // If it's auto save, should not save the step
    return this.getPage(path, queryParams, !autoNavigation)
      .then((page) => {
        this.formIds = [];
        return this.showPage(page).then(() => {
          this.setEventHandler();
          this.preventClick = false;
          return Promise.resolve();
        });
      })
      .catch((err) => { });
  }

  private getPage(path: string, queryParams: any, shouldSave: boolean = true): Promise<Page> {
    let templateValue;
    let templateStep: number;
    const standalone = this.flowContent.standAlone.find((s) => s === path);

    if (standalone) {
      if (standalone === 'unsubscribe' && queryParams.params.code) {
        this.setUnsubscribeEvent = true;
      }

      const standAloneKey = this.getTemplateKey(standalone);
      this.setTitle(standAloneKey);

      let queryParamsString = new HttpParams({ fromObject: queryParams.params }).toString();

      if (this.survey.location === '') {
        this.location.go(standalone.replace(' ', '-'), queryParamsString);
      } else {
        this.location.go(`${this.survey.location}/${standalone.replace(' ', '-')}`, queryParamsString);
      }

      return this.surveyService.getPage(this.survey.id, standAloneKey, this.language).catch((error) => this.handleError(error));
    }

    const pathIsStep = this.flowContent.steps.find((step) => step.value === path);

    if (pathIsStep || path === this.survey.location) {
      this.flowContent.steps.forEach((keyValue) => {
        if (keyValue.key === this.currentStep) {
          templateStep = keyValue.key;
          templateValue = keyValue.value;
        }
      });
    }

    if (!templateValue) {
      return this.showError('404');
    }

    const templateKey = this.getTemplateKey(templateValue);
    this.setTitle(templateKey);

    if (this.currentStep !== this.loadingStep) {
      this.loadingStep = this.currentStep;
      let insertAnswer = Promise.resolve();

      if (shouldSave && this.currentStep === this.flowContent.steps.length - 1) {
        this.deleteAnswerState(this.survey.id);

        const surveyAnswer: SurveyAnswer = {
          id: this.surveyAnswerState.answerId,
          surveyId: this.survey.id,
          state: SurveyState.Completed,
          answersJson: JSON.stringify(this.data),
          timezoneOffset: new Date().getTimezoneOffset(),
          answerLanguage: this.language,
          pageDisplayed: this.socialMediaPageDisplayed,
        };

        this.renewSavingCancellationToken();
        insertAnswer = insertAnswer.then(() => {
          return new Promise((y, n) => {
            this.surveyService.saveSurveyAnswer(surveyAnswer, this.recaptchaToken, this.survey.customImportEntryId).subscribe(
              (obs) => {
                y();
              },
              (err) => n()
            );
          });
        });
      }

      return insertAnswer.then(() =>
        this.surveyService
          .getPage(this.survey.id, templateKey, this.language)
          .then((page) => {
            this.currentStep += 1;
            this.isLoading = false;
            return Promise.resolve(page);
          })
          .catch((error) => this.handleError(error))
      );
    }

    return Promise.reject();
  }

  private showPage(page: Page): Promise<void | void[]> {
    this.showOverlay();
    return this.display(page).then((html) => {
      const loadForms: Promise<void>[] = [];
      this.surveyContainer.nativeElement.innerHTML = html;

      this.formIds.forEach((id) => {
        loadForms.push(this.loadForm(id, this.formLoadedIndex++));
      });

      return Promise.all(loadForms).then(() => this.hideOverlay());
    });
  }

  private display(page: Page): Promise<string> {
    if (page.layoutData) {
      return this.replaceTags(page.layoutData, JSON.parse(page.layoutValues)).then((convertedHtmlString) => {
        const body = { body: page.templateData };
        convertedHtmlString = convertedHtmlString.replace('<!---', '<!--');
        return this.replaceTags(convertedHtmlString, body).then((secondConversion) => {
          return this.replaceTags(secondConversion, JSON.parse(page.templateValues));
        });
      });
    } else {
      return this.replaceTags(page.templateData, JSON.parse(page.templateValues));
    }
  }

  private replaceTags(convertedHtmlString: string, values: any): Promise<string> {
    const buildReplacedString = (text, matchCount?): Promise<string> => {
      return new Promise((yes, no) => {
        const matches = text.match(this.globalRegex);
        let resultText = text;

        let buildChain = Promise.resolve(resultText);
        if (matches) {
          matches.forEach((match) => {
            buildChain = buildChain
              .then(() => this.convertTagToHtml(match, values))
              .then((replacement) => {
                resultText = resultText.replace(match, replacement);
                return Promise.resolve(resultText);
              });
          });
        }

        buildChain.then((t) => {
          yes(t);
        });
      });
    };

    return buildReplacedString(convertedHtmlString, 0);
  }

  private getBrandingInfo(): Observable<BrandingInfo> {
    let branchId = null;

    for (const i of this.data) {
      if (i.answersJson.branchId) {
        branchId = i.answersJson.branchId;
        break;
      }
    }

    return new Observable<BrandingInfo>((observer) => {
      if (!this.brandingInfo || (!this.brandingInfo.branch && branchId)) {
        if (branchId) {
          this.surveyService.getCorporateBrandInfoByBranch(branchId, this.language).subscribe((branding) => {
            this.brandingInfo = branding;
            observer.next(branding);
          });
        } else {
          this.surveyService.getCorporateBrandInfo(this.surveyCode, this.language).subscribe((branding) => {
            this.brandingInfo = branding;
            observer.next(branding);
          });
        }
      } else {
        observer.next(this.brandingInfo);
      }
    });
  }

  private convertTagToHtml(tag, values): Promise<string> {
    return new Promise((yes, no) => {
      const groups = this.regex.exec(tag);
      const hexaTag = groups[2];
      const propertyKey = groups[4];

      // Check for tags that doesn't use 'value' such as import, organization, branch, and subscription.

      switch (hexaTag) {
        case 'subscription':
          this.getBrandingInfo().subscribe(
            (info) => {
              if (propertyKey === 'name') {
                yes(info.subscription.name);
              } else if (propertyKey === 'logo') {
                yes(`<img src="${info.subscription.logo}" alt="${info.subscription.name}" ${this.getMarvelTagAttributes(tag, true)}>`);
              }
            },
            (err) => no()
          );
          return; // Function must end here to wait for call to finish.
        case 'organization':
          this.getBrandingInfo().subscribe(
            (info) => {
              if (propertyKey === 'name') {
                yes(info.organization.name);
              } else if (propertyKey === 'logo') {
                yes(`<img src="${info.organization.logo}" alt="${info.organization.name}" ${this.getMarvelTagAttributes(tag, true)}>`);
              }
            },
            (err) => no()
          );
          return; // Function must end here to wait for call to finish.
        case 'branch':
          this.getBrandingInfo().subscribe(
            (info) => {
              if (info.branch) {
                if (propertyKey === 'name') {
                  yes(info.branch.name);
                } else if (propertyKey === 'logo') {
                  yes(`<img src="${info.branch.logo}" alt="${info.branch.name}" ${this.getMarvelTagAttributes(tag, true)}>`);
                }
              } else {
                yes('');
              }
            },
            (err) => no()
          );
          return; // Function must end here to wait for call to finish.
        case 'branch-social-media':
          this.surveyService.getBranchSocialMedia(this.data).subscribe(
            (accounts) => {
              const attributes = this.getMarvelTagAttributes(tag);
              const value = values[propertyKey];
              const altValue = attributes.alternateText ? values[attributes.alternateText] : '';
              let list = '';

              if (accounts) {
                for (let i = 0; i < accounts.length; i++) {
                  let account = accounts[i];

                  if (!account.url.startsWith('http://') && !account.url.startsWith('https://')) {
                    account.url = `https://${account.url}`;
                  }

                  account.url = account.url.replace('{0}', this.surveyAnswerState.answerId);

                  let imgSource = 'assets/socialmedia/';
                  switch (account.type) {
                    case SocialMedia.GoogleReviews:
                      imgSource = `${imgSource}google.svg`;
                      break;
                    case SocialMedia.TripAdvisor:
                      imgSource = `${imgSource}tripadvisor.svg`;
                      break;
                    case SocialMedia.Yelp:
                      imgSource = `${imgSource}yelp.svg`;
                      break;
                    case SocialMedia.FourSquare:
                      imgSource = `${imgSource}foursquare.svg`;
                      break;
                    case SocialMedia.Facebook:
                      imgSource = `${imgSource}facebook.svg`;
                      break;
                    case SocialMedia.BingReviews:
                      imgSource = `${imgSource}bing.svg`;
                      break;
                  }

                  list += `<a target="blank" href="${account.url}"><img src="${imgSource}" /></a>`;
                }

                this.socialMediaPageDisplayed = true;
                this.surveyService.saveSurveyAnswerPageDisplayed(this.surveyAnswerState.answerId, this.socialMediaPageDisplayed).subscribe((r) => r);
              }

              yes(`<div ${this.getMarvelTagAttributes(tag, true)}>${accounts && accounts.length && value ? value : altValue}<div>${list}</div></div>`);
            },
            (err) => no()
          );
          return; // Function must end here to wait for call to finish.
        case 'import':
          if (propertyKey === groups[4] && propertyKey === 'code') {
            this.logger.warning('Voc template is using an import property code but this is not supported for the VoC', this.loggerFrom);
            yes('');
          } else {
            const jsonPropertyKey = groups[4];
            const customImportProperty = this.getCustomImportField(jsonPropertyKey);

            if (customImportProperty) {
              yes(customImportProperty);
            } else {
              this.logger.warning(
                'Voc template is using an import property from a custom import but json property was not found in the custom import',
                this.loggerFrom,
                jsonPropertyKey
              );
            }
          }

          break;
        case 'query-string':
        case 'queryString':
          this.route.queryParams.subscribe(
            (observer) => {
              if (observer[propertyKey] !== undefined && observer[propertyKey] !== null) {
                yes(observer[propertyKey]);
              } else {
                yes('');
              }
            },
            (err) => no()
          );
          return; // Function must end here to wait for call to finish.
        case 'query-string-restriction':
          this.route.queryParams.subscribe(
            (observer) => {
              var attributes = this.getMarvelTagAttributes(tag);
              if (attributes) {
                if (attributes.type === 'currentDate') {
                  const now = Date.now();
                  const date = attributes.dateFormat ? moment(observer[propertyKey], attributes.dateFormat).toDate().valueOf() : Date.parse(observer[propertyKey]);

                  const limit = parseInt(attributes.days) * 24 * 60 * 60 * 1000;

                  if (!Number.isNaN(limit)) {
                    if (attributes.operator === 'lessThan') {
                      var diff = now - date;
                      if (diff > limit) {
                        this.showError(attributes.errorCode);
                      }
                    }
                  }
                }
              }

              yes('');
            },
            (err) => no()
          );
          return; // Function must end here to wait for call to finish.
        case 'voc':
          if (propertyKey === groups[4] && propertyKey === 'primary-url') {
            yes(`${this.survey.primaryUrl}`);
          }
          break;
        case 'cookie':
          if (propertyKey === 'popup' && localStorage.getItem('acceptedCookies:' + location.host) === null) {
            let attributes = this.getMarvelTagAttributesCookiesUrl(tag, false);
            let cookiePolicyUrl = attributes.url;

            if (attributes[`url-${this.language}`]) {
              cookiePolicyUrl = attributes[`url-${this.language}`];
            } else if (attributes[`url`]) {
              cookiePolicyUrl = attributes[`url`];
            } else {
              this.cookiePolicyText = '';
            }

            yes(`<div id="cookie-popup" style="
                  position: fixed;
                  z-index: 2000;
                  bottom: 8px;
                  left: 8px;
                  width: 300px;
                  min-height: 400px;
                  background-color:white;
                  border-radius: 5px;
                  border: 1px solid #aaa;
                  box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.40);
                  padding: 16px">
                    <h2>Cookies</h2>
                    <p>${this.cookiePopupText}</p>
                    <div style="margin-bottom: 15px;"><a href=${cookiePolicyUrl} target="_blank" style="text-decoration: none;">${this.cookiePolicyText}</a></div>
                    <div>
                      <input type="button" value="${this.cookiePopupAcceptButton}" style="background-color: black; border: none; color: white; padding: 4px 8px;" onclick="javascript:acceptCookies('${location.host}');" />
                      <input type="button" value="${this.cookiePopupRefuserButton}" style="background-color: white; border: 1px solid black; color: black; padding: 3px 7px;"  onclick="javascript:declineCookies('${location.host}');" />
                    </div>
                  </div>`);
          }
          break;
      }

      // Check if we have any tags with data
      for (const key in values) {
        if (key === propertyKey) {
          const value = values[key];
          switch (hexaTag) {
            case 'image':
              yes(`<img src="${value[0].content}" alt="${value[0].name}" ${this.getMarvelTagAttributes(tag, true)}>`);
              break;
            case 'imageSrc':
              yes(value[0].content);
              break;
            case 'text':
              let convertedHtml = value.replace('"', '&quot;');
              convertedHtml = convertedHtml.replace('&', '&amp;');
              convertedHtml = convertedHtml.replace('<', '&lt;');
              convertedHtml = convertedHtml.replace('>', '&gt;');
              yes(convertedHtml);
              break;
            case 'html':
              yes(value);
              break;
            case 'form':
              this.formIds.push(value);
              yes(`<div id='${value}'></div>`);
              break;
            case 'alert':
              var message = `<div id="alert-${key}">${value}</div>`;
              $('body').append(message);
              $(`#alert-${key}`).dialog({
                modal: true,
                buttons: {
                  Ok: function () {
                    $(this).dialog('close');
                  },
                },
              });
              yes('');
              break;
            case 'body':
              yes(value);
              break;
            default:
              yes(value);
              break;
          }

          return;
        }
      }

      if (groups[2] === 'body') {
        tag = tag.replace('<!--', '<!---');
      }

      yes(tag);
    });
  }

  private getErrorPage(errorCode: string): Promise<Page> {
    this.logger.warning('Handling error', this.loggerFrom, errorCode);
    let templateValue = this.flowContent.errors.find((error) => error === `${errorCode}`);
    if (!templateValue) {
      templateValue = this.flowContent.errors.find((error) => error === 'default');
    }

    if (!templateValue) {
      return Promise.reject('Template cannot be found!');
    }

    const templateKey = this.getTemplateKey(templateValue);
    this.setTitle(templateKey);
    return this.surveyService.getPage(this.survey.id, templateKey, this.language);
  }

  private handleError(reason: any): Promise<Page> {
    return this.showError(reason.status);
  }

  private showError(errorCode: string): Promise<Page> {
    return this.getErrorPage(errorCode)
      .then((page) => {
        return this.showPage(page).then(() => page);
      })
      .catch((error) => {
        this.logger.error(error, 'handleError()');
        return null;
      });
  }

  private getTemplateKey(templateValue: string): string {
    const templateKeyValue = this.flowContent.templates.find((keyValue) => keyValue.value === templateValue);
    return templateKeyValue ? templateKeyValue.key : '';
  }

  private formCompletionEvents(surveyJsModel: SurveyAngular.Model, targetPage: any, formVersion: FormVersion) {
    let duplicatedReceiptCodeError: SurveyAngular.SurveyError;
    // When the form is completed
    surveyJsModel.onComplete.add((sender, options) => {
      surveyJsModel.clear(false, false);

      if (!this.preventClick) {
        var savePromise = Promise.resolve();
        savePromise = savePromise.then(() => this.showOverlay());

        var receiptCodeElement = targetPage.elements.filter((element) => element.codeMapping === true);
        if (receiptCodeElement && receiptCodeElement.length !== 0) {
          var receiptcode = sender.getValue(receiptCodeElement[0].name);
          //var receiptcode = this.workAroundSurveyJSSpaceValueChangeIssue(sender, receiptCodeElement);

          if (receiptcode != undefined) {
            this.surveyService.getMappedReceiptCode(receiptcode, this.language).then((x) => {
              if (!x.hasError) {
                this.receiptMappings = x.codeMappings;
                this.setReceiptMappedFields(sender);
                savePromise = savePromise.then(() => this.changePageOnAnswerPartialState(surveyJsModel, targetPage, formVersion, sender, options));
              } else {
                // Manually display error message
                var receiptCodeElement = targetPage.elements.filter((element) => element.codeMapping === true);
                var receiptQuestion = surveyJsModel.getQuestionByName(receiptCodeElement[0].name);

                this.translateService.get('houston_code_not_valid').subscribe((translation) => {
                  if (!duplicatedReceiptCodeError) {
                    duplicatedReceiptCodeError = new SurveyAngular.SurveyError(translation);
                  } else {
                    receiptQuestion.removeError(duplicatedReceiptCodeError);
                  }

                  receiptQuestion.addError(duplicatedReceiptCodeError);
                  this.hideOverlay();
                });

                this.logger.warning('Something went wrong with the receipt code...', this.loggerFrom);
              }
            });
          } else {
            savePromise = savePromise.then(() => this.changePageOnAnswerPartialState(surveyJsModel, targetPage, formVersion, sender, options));
          }
        } else {
          savePromise = savePromise.then(() => this.changePageOnAnswerPartialState(surveyJsModel, targetPage, formVersion, sender, options));
        }

        // Removing this makes the overlay
        // savePromise.then(() => this.hideOverlay());
      } else {
        this.logger.warning('Double click prevented', this.loggerFrom);
      }
    });

    surveyJsModel.onCompleting.add((survey) => {
      this.showOverlay();
    });
  }

  // MJG 2022-05-25: DO NOT USE! Messes with data collection as it unallocates all values related that rely on the receipt code!
  // private workAroundSurveyJSSpaceValueChangeIssue(sender: SurveyAngular.SurveyModel, receiptCodeElement: any) {
  //   //Survey js does not detect changes to spaces at the beginning or end of a value as a value change.
  //   var receiptcode = sender.getValue(receiptCodeElement[0].name).trim();
  //   sender.setValue(receiptCodeElement[0].name, '');
  //   sender.setValue(receiptCodeElement[0].name, receiptcode);
  //   return receiptcode;
  // }

  private changePageOnAnswerPartialState(
    surveyJsModel: SurveyAngular.Model,
    targetPage: any,
    formVersion: FormVersion,
    sender: SurveyAngular.SurveyModel,
    options: any
  ): Promise<void> {
    this.surveyAnswerState.page += 1;
    this.saveAnswerState(this.survey.id);

    const xFormElements = targetPage.elements.filter((element) => element.crossForm === true);
    if (xFormElements) {
      xFormElements.forEach((element) => {
        this.xForms.push({
          name: 'xForm_'.concat(element.name),
          value: sender.getValue(element.name),
        });
      });
    }

    surveyJsModel.clear(false, false);
    return this.saveAnswer(formVersion.id, sender.data).catch(() => options.showDataSavingError('error'));
  }

  private formRenderEvents(surveyJsModel: SurveyAngular.Model, targetPage: any, formVersion: FormVersion) {
    surveyJsModel.onAfterRenderSurvey.add((survey) => {
      if (!this.firstLoadFormIds.some((id) => id === formVersion.id)) {
        this.firstLoadFormIds.push(formVersion.id);
        this.hideOverlay();
      }
    });

    var isSurveyCompleted = false;

    surveyJsModel.onCompleting.add((survey, options) => {
      isSurveyCompleted = true;
    });

    // Testing
    var divId = !formVersion ? null : formVersion.formId;
    var targetElements = '.sv_row, .sv-row';
    var duration = 300;
    var distance = 250;
    var isPrevPage = false;

    const sendOut = () => {
      const translateX = isPrevPage ? [0, distance] : [0, distance * -1];
      let targets = document.getElementById(divId);
      targets = $(targets).find(targetElements).get();

      anime({
        targets: targets,
        translateX,
        opacity: 0,
        easing: 'easeOutExpo',
        duration,
      });
    };

    const bringIn = () => {
      const translateX = isPrevPage ? [distance * -1, 0] : [distance, 0];
      var targets = document.getElementById(divId);
      targets = $(targets).find(targetElements).css('opacity', '0').get();
      anime({
        targets: targets,
        translateX,
        opacity: 1,
        easing: 'easeInExpo',
        duration: duration,
      });
    };

    let startAnimation = true;
    surveyJsModel.onCurrentPageChanging.add((sender, options) => {
      if (!startAnimation) {
        return;
      }

      // This might screw up on non-SaaS voc.
      distance = ($('body').width() - $('.card-body > [id^="sp_"]').width()) / 2.0;

      options.allowChanging = false;

      this.zone.runOutsideAngular(() => {
        setTimeout(function () {
          startAnimation = false;
          sender.currentPage = options.newCurrentPage;
          startAnimation = true;
        }, duration);

        isPrevPage = options.isPrevPage;
        sendOut();
      });
    });

    surveyJsModel.onAfterRenderQuestionInput.add((sender, options) => {
      var el = options.htmlElement;
      var question = options.question;
      if (question.getType() == 'text' && question.titleLocation === 'hidden' && this.activatedStyle == 'bootstrapmaterial') {
        $(`<label class="bmd-label-floating">${question.locTitle.textOrHtml}</label>`).insertBefore(el);
        $(el).parent().bootstrapMaterialDesign();
      }
    });

    surveyJsModel.onCurrentPageChanged.add(function (sender) {
      bringIn();
    });

    // END testing

    surveyJsModel.onAfterRenderQuestion.add((question, options) => {
      const element = targetPage.elements.find((x) => x.name === options.question.name);

      if (!element) {
        return;
      }

      if (element.queryStringParameter) {
        if (!isSurveyCompleted) {
          this.checkIfPropertyFilledByQueryString(surveyJsModel, element);
        }
      }

      if (element.renderAs) {
        var renderType = element.renderAs;
        if (renderType === 'prettycheckbox') {
          //RR TODO: Implement prettycheckbox
        }
      }

      if (element.moreInfoUrl) {
        const link = document.createElement('a');
        link.href = element.moreInfoUrl;
        link.target = '_blank';
        //link.className = 'btn btn-info btn-xs';
        link.innerHTML = '?';
        const question = options.question;
        const header = options.htmlElement.querySelector('h5');
        header.className = 'more-info-prior';
        const span = document.createElement('span');
        span.innerHTML = '  ';
        header.appendChild(span);
        header.parentNode.insertBefore(link, header.nextSibling);
      }
    });
  }

  private formValidationEvents(surveyJsModel: SurveyAngular.Model, targetPage: any, formVersion: FormVersion) {
    let duplicatedReceiptCodeError: SurveyAngular.SurveyError;

    surveyJsModel.onServerValidateQuestions.add((survey, options) => {
      let validationChain = Promise.resolve();
      validationChain.then(() => this.showOverlay());

      var answers = Object.keys(options.data);

      answers.forEach((answer) => {
        validationChain = validationChain.then(
          () =>
            new Promise((yes, no) => {
              if (!this.fieldNeedValidation(targetPage.elements, answer)) {
                yes();
                return;
              }

              const serviceUrl = targetPage.elements.find((i: any) => i.name === `${answer}_url`);

              if (!serviceUrl) {
                yes();
                return;
              }

              let code = options.data[answer];
              let url = serviceUrl.defaultValue;

              if (url.indexOf(`{`) >= 0) {
                code = null;

                var regex = /(\{.*?\})/g;
                var matches = url.match(regex);

                for (var key in matches) {
                  var questionName = matches[key].replace('{', '').replace('}', '');
                  url = url.replace(matches[key], options.data[questionName]);
                }
              }

              this.customValidationService.validate(url, code).then((validationResult) => {
                this.logger.info('Validation Result: ', this.loggerFrom, validationResult);

                if (validationResult.result) {
                  for (const key in validationResult.message) {
                    if (key !== '$type' && validationResult.message.hasOwnProperty(key)) {
                      const qValue = validationResult.message[key];
                      surveyJsModel.setValue(key, qValue);

                      this.logger.info(`${key} Value: `, this.loggerFrom, qValue);
                    }
                  }

                  yes();
                } else {
                  this.translateService.get(validationResult.message.error).subscribe((translation) => {
                    options.errors[answer] = translation;
                    yes();
                  });
                }
              });
            })
        );
      });

      validationChain = validationChain.then(
        () =>
          new Promise((yes, no) => {
            let surveyAnswer: SurveyAnswer;

            // Prepare the answer
            surveyAnswer = {
              id: this.surveyAnswerState.answerId,
              surveyId: this.survey.id,
              state: SurveyState.InProgress,
              answersJson: this.combinePartialAnswers(formVersion.id, survey.data),
              timezoneOffset: new Date().getTimezoneOffset(),
              answerLanguage: this.language,
              pageDisplayed: this.socialMediaPageDisplayed,
            };

            // Call to server
            this.surveyService.validateReceipt(surveyAnswer).then(
              (val) => {
                if (val.result) {
                  yes();
                } else {
                  const receiptUsed = document.getElementsByClassName('receiptused');
                  if (receiptUsed && receiptUsed.length) {
                    for (let i = 0; i < receiptUsed.length; i++) {
                      if (receiptUsed[i].hasAttribute('hidden')) {
                        receiptUsed[i].removeAttribute('hidden');
                        receiptUsed[i].scrollIntoView();
                      }
                    }
                  }

                  this.translateService.get(val.message.error).subscribe((translation) => {
                    const receiptCodeElements = targetPage.elements.filter((element) => element.codeMapping === true);
                    const element = receiptCodeElements[0];
                    let customValidationTranslation = null;

                    if (element.validators && element.validators.length) {
                      const validator = element.validators.find((v) => v.expression === `!!'${val.message.error}'`);
                      if (validator) {
                        customValidationTranslation = validator.text[this.language] || validator.text.default;
                      }
                    }

                    options.errors[element.name] = customValidationTranslation || translation;
                    no();
                  });
                }
              },
              (reject) => {
                no();
              }
            );
          })
      );

      // Only validate recaptcha on the submission to the backend which happen on the very last step.
      // The library have an issue when calling execute multiple times and beside the backend will only
      // validate the token when the survey is fully Completed.
      if (this.currentStep === this.flowContent.steps.length - 1) {
        validationChain = validationChain.then(() => {
          return new Promise<void>((yes, no) => {
            // The recaptcha challenge doesn't scroll the page so it's rendering outside the view of the user.
            // This code will scroll to it.
            this.logger.info('Attempting to scroll to recaptcha challenge');
            this.zone.runOutsideAngular(() => {
              var timesRun = 0;

              var interval = setInterval(() => {
                timesRun += 1;

                if (timesRun === 5) {
                  clearInterval(interval);
                }

                var challengeParent = $('iframe[title="recaptcha challenge"]').parent()[0];

                if (challengeParent) {
                  $([document.documentElement, document.body]).animate(
                    {
                      scrollTop: $(challengeParent).position().top + $(challengeParent).height(),
                    },
                    500
                  );
                } else {
                  clearInterval(interval);
                }
              }, 100);
            });

            if (this.recaptchaSub !== Subscription.EMPTY) {
              this.recaptchaSub.unsubscribe();
            }

            this.recaptchaSub = this.recaptchaComponent.token.subscribe((token: string) => {
              if (token != null) {
                this.recaptchaToken = token;
                yes();
              } else {
                // If the user abandon the recaptcha test
                no();
              }
            });
            // Make sure to subscribe to the token before calling execute.
            this.recaptchaComponent.execute();
          });
        });
      }

      validationChain.finally(() => {
        this.hideOverlay();
        options.complete();
      });
    });
  }

  private getMarvelTagAttributes(tag: string, returnHtmlAttributes: boolean = false): any | string {
    if (!tag) return '';

    let regex = new RegExp(this.attrRegex);
    let match = regex.exec(tag);
    if (!match) return '';

    let attributes = {};

    while (match != null) {
      attributes[match[1]] = match[2];
      match = regex.exec(tag);
    }

    if (returnHtmlAttributes) {
      const keys = Object.keys(attributes);
      return keys.map((k) => `${k}="${attributes[k]}"`).join(' ');
    }

    return attributes;
  }

  private getMarvelTagAttributesCookiesUrl(tag: string, returnHtmlAttributes: boolean = false): any | string {
    if (!tag) return '';
    let regex = new RegExp(this.attrRegexUrl);

    let match = regex.exec(tag);
    if (!match) return '';

    let attributes = {};

    while (match != null) {
      attributes[match[1]] = match[2];
      match = regex.exec(tag);
    }

    if (returnHtmlAttributes) {
      const keys = Object.keys(attributes);
      return keys.map((k) => `${k}="${attributes[k]}"`).join(' ');
    }

    return attributes;
  }

  private formValueChangeEvents(surveyJsModel: SurveyAngular.Model, formVersion: FormVersion) {
    // Saving form changes
    surveyJsModel.onValueChanged.add((survey, options) => {
      let surveyAnswer: SurveyAnswer;

      // Prepare the answer
      surveyAnswer = {
        id: this.surveyAnswerState.answerId,
        surveyId: this.survey.id,
        state: SurveyState.InProgress,
        answersJson: this.combinePartialAnswers(formVersion.id, survey.data),
        timezoneOffset: new Date().getTimezoneOffset(),
        answerLanguage: this.language,
        pageDisplayed: this.socialMediaPageDisplayed,
      };

      if (this.loading) {
        this.loading.unsubscribe();
      }

      var keys = Object.keys(survey.data);
      // Only start tracking once the client has offered a non-default answer.
      if (keys.some((k) => k !== this.languageQuestion)) {
        this.renewSavingCancellationToken();

        this.loading = this.surveyService
          .saveSurveyAnswer(surveyAnswer, '')
          .pipe(takeUntil(this.savingCancellationToken))
          .pipe(takeUntil(this.cancellationToken))
          .subscribe((actionResult) => {
            this.surveyAnswerState.answerId = actionResult.id;
            if (actionResult.globalScore || actionResult.globalScore === 0) {
              survey.setValue('globalScore', actionResult.globalScore);
            }

            this.saveAnswerState(this.survey.id);
          });
      }
    });

    surveyJsModel.onVisibleChanged.add((survey, options) => {
      if (!options.visible) {
        survey.clearValue(options.name);
      }
    });
  }

  private formVariousEvents(surveyJsModel: SurveyAngular.Model) {
    const converter = new showdown.Converter();
    surveyJsModel.onTextMarkdown.add((survey, options) => {
      let str = converter.makeHtml(options.text);
      str = str.substring(3);
      str = str.substring(0, str.length - 4);
      options.html = str;
    });
  }

  private formLoadFromServerEvents(surveyJsModel: SurveyAngular.Model, targetPage: any) {
    surveyJsModel.onLoadChoicesFromServer.add((survey, options) => {
      const valueByUrlElements = targetPage.elements.filter((element) => element.valueByUrl === true);
      const selectAllFromWebElements = targetPage.elements.filter((element) => element.selectAllChoicesFromWeb === true);

      if (valueByUrlElements) {
        valueByUrlElements.forEach((valueByUrl) => {
          if (valueByUrl.name === options.question.name) {
            if (options.serverResult && options.serverResult[0]) {
              survey.setValue(valueByUrl.name, options.serverResult[0].value);
            }
          }
        });
      }

      if (selectAllFromWebElements) {
        selectAllFromWebElements.forEach((element) => {
          if (element.name === options.question.name) {
            // Automatically select all choices after loading them.
            const itemValues = options.choices.map((element) => element.itemValue);
            options.question.defaultValue = itemValues;
          }
        });
      }
    });
  }

  private loadForm(formId: string, currentFormIndex: number): Promise<void> {
    showdown.setOption('underline', true);

    return this.surveyService.getForm(formId).then((formVersion) => {
      SurveyAngular.StylesManager.applyTheme(this.activatedStyle);
      this.meta.addTag({ name: 'form-version', content: formVersion.majorVersion + '.' + formVersion.minorVersion });

      const formObj = JSON.parse(formVersion.formJson);
      const surveyJsModel = new SurveyAngular.Model(formObj);
      const currentPageObj = formObj.pages.find((p: any) => p.name === surveyJsModel.currentPage.name);

      this.logger.info('Page Form: ', this.loggerFrom, currentPageObj);

      surveyJsModel.clearInvisibleValues = false;
      surveyJsModel.clearValueOnDisableItems = true;

      this.formRenderEvents(surveyJsModel, currentPageObj, formVersion);
      this.formValueChangeEvents(surveyJsModel, formVersion);

      this.formValidationEvents(surveyJsModel, currentPageObj, formVersion);
      this.formLoadFromServerEvents(surveyJsModel, currentPageObj);

      this.formCompletionEvents(surveyJsModel, currentPageObj, formVersion);
      this.formVariousEvents(surveyJsModel);

      // Setting language for the form
      surveyJsModel.locale = this.language;

      let ready = Promise.resolve();
      const answerState = this.getAnswerState(this.survey.id);

      // Wait to load branches before pre-fill form fields
      if (answerState) {
        ready = ready.then(() => this.loadPartialAnswer(surveyJsModel, formVersion, currentFormIndex));

        const branchIdExists = formObj.pages.some((page) => page.elements.some((element) => element.name === 'branchId' && element.visible !== false));
        if (branchIdExists) {
          ready.then(
            () =>
              new Promise<void>((y, n) => {
                // Waiting to the branches to be loaded before load the form
                surveyJsModel.onLoadChoicesFromServer.add((survey, options) => {
                  this.logger.success('Branches loaded Answer', this.loggerFrom, options);

                  // Loading a partial answer to the survey
                  if (options.question.name === 'branchId') {
                    y();
                  }
                });
              })
          );
        }
      }

      ready.then(() => {
        this.addXFormValue(surveyJsModel);
        surveyJsModel.setValue(this.languageQuestion, this.language);

        this.setReceiptMappedFields(surveyJsModel);
        this.setCustomImportMappedFields(surveyJsModel);
        if (this.paramBranchId && surveyJsModel.currentPage.elements.find((f) => f.name === 'branchId')) {
          surveyJsModel.setValue('branchId', this.paramBranchId);
        }
        currentPageObj.elements.forEach((q) => {
          if (q.queryStringParameter) {
            this.checkIfPropertyFilledByQueryString(surveyJsModel, q);
          }
        });

        this.zone.runOutsideAngular(() => {
          // Rendering the Survey form outside angular
          SurveyAngular.SurveyNG.render(formId, {
            model: surveyJsModel,
            css: {
              footer: 'panel-footer',
              navigationButton: 'btn btn-lg btn-primary ml-3',
            },
          });

          setInterval(() => {
            if ($('.ui-datepicker').length) {
              $('.ui-datepicker').addClass('notranslate');
            }
          }, 1000);
        });
      });

      return Promise.resolve();
    });
  }

  private getCustomImportField(jsonProperty: string): string {
    if (this.survey.customImportEntryContent) {
      return JSON.parse(this.survey.customImportEntryContent)[jsonProperty];
    }

    return null;
  }

  private setCustomImportMappedFields(surveyJsModel: SurveyAngular.Model): void {
    for (const key in this.customImportMappings) {
      if (this.customImportMappings.hasOwnProperty(key)) {
        const mapping = this.customImportMappings[key];

        const value = JSON.parse(this.survey.customImportEntryContent)[mapping.jsonProperty];

        if (typeof value === 'boolean') {
          // If it's a boolean and it's true, just set to 1,
          // else need to get the actual value and set it
          if (value) {
            surveyJsModel.setValue(mapping.questionName, '1');
          } else {
            const falseValue = surveyJsModel.getQuestionByName(mapping.questionName).choices.find((c) => c.value !== '1').value;
            surveyJsModel.setValue(mapping.questionName, falseValue);
          }
        } else {
          surveyJsModel.setValue(mapping.questionName, value);
        }

        this.logger.success('Setting imported field', this.loggerFrom, surveyJsModel.getValue(mapping.questionName));
      }
    }
  }

  private setReceiptMappedFields(surveyJsModel: SurveyAngular.SurveyModel): void {
    for (const key in this.receiptMappings) {
      if (this.receiptMappings.hasOwnProperty(key)) {
        const mapping = this.receiptMappings[key];

        if (mapping.questionName) {
          surveyJsModel.setValue(mapping.questionName, mapping.jsonProperty);
          this.logger.success('Setting receipt mapped field', this.loggerFrom, surveyJsModel.getValue(mapping.questionName));
        }
      }
    }
  }

  checkIfPropertyFilledByQueryString(survey: SurveyAngular.Survey, element: any) {
    if (element.queryStringParameter) {
      const parameterToRead = element.queryStringParameter;
      const queryStringValue = this.paramMap.get(parameterToRead);

      if (queryStringValue) {
        switch (element.type) {
          case 'text':
            survey.setValue(element.name, queryStringValue);
            break;
          case 'radiogroup':
            survey.setValue(element.name, queryStringValue);
            break;
        }
      }
    }
  }

  private addXFormValue(survey: SurveyAngular.SurveyModel) {
    this.xForms.forEach((element) => {
      survey.setValue(element.name, element.value);
    });
  }

  private clearXFormValue(survey: SurveyAngular.SurveyModel) {
    this.xForms.forEach((element) => {
      survey.clearValue(element.name);
    });
  }

  private fieldNeedValidation(currentPageElements: any[], fieldName: string): boolean {
    if (currentPageElements.find((f) => f.name === fieldName && f.fieldToValidate)) {
      return true;
    }

    return false;
  }

  private loadPartialAnswer(surveyJsModel: SurveyAngular.Survey, formVersion: FormVersion, currentFormIndex: number): Promise<void> {
    const answerState = this.getAnswerState(this.survey.id);
    if (answerState) {
      return new Promise((y, n) => {
        this.surveyService.getSurveyAnswer(answerState.answerId).subscribe(
          (partialAnswer) => {
            // MJG - We no longer autonavigate.
            // MJG - The order is significant!
            if (partialAnswer) {
              this.surveyAnswerState.answerId = partialAnswer.id;
              this.data = JSON.parse(partialAnswer.answersJson);

              if (this.data.length > currentFormIndex) {
                const formState: FormAnswer = this.data[currentFormIndex];
                if (formState.id === formVersion.id) {
                  // Getting the form answers based on the form version id
                  surveyJsModel.data = formState.answersJson;

                  this.logger.success('Partial Answer', this.loggerFrom, surveyJsModel.data);
                } else {
                  this.data.splice(currentFormIndex);
                }
              }
            }
            y();
          },
          (err) => n()
        );
      });
    } else {
      return Promise.resolve();
    }
  }

  private saveAnswerState(surveyId: string): void {
    localStorage.setItem(`${environment.partialAnswerKey}:${surveyId}`, JSON.stringify(this.surveyAnswerState));
  }

  private deleteAnswerState(surveyId: string): void {
    localStorage.removeItem(`${environment.partialAnswerKey}:${surveyId}`);
  }

  private getAnswerState(surveyId: string): { page: number; answerId: string } {
    const answerState = localStorage.getItem(`${environment.partialAnswerKey}:${surveyId}`);

    if (!answerState) {
      return null;
    }

    return JSON.parse(answerState);
  }

  private combinePartialAnswers(formVersionId: string, partialData: any, jsonObj: boolean = false): any {
    // If it's not empty need to combine both answers
    const partialAnswers: FormAnswer[] = this.data;

    // There is answers for this form
    const answersIndex = partialAnswers.findIndex((a) => a.id === formVersionId);

    if (answersIndex > -1) {
      // Replace the existent item
      partialAnswers[answersIndex] = {
        id: formVersionId,
        answersJson: partialData,
      };
    } else {
      // There is no answer for current form yet, should be added
      partialAnswers.push({ id: formVersionId, answersJson: partialData });
    }

    if (jsonObj) {
      return partialAnswers;
    }

    return JSON.stringify(partialAnswers);
  }

  private saveAnswer(id: string, answers: object): Promise<void> {
    this.logger.success('Survey Data', this.loggerFrom, this.data);
    this.preventClick = true;

    if (this.data.length === 0) {
      this.data = [{ id, answersJson: answers }];
    } else {
      this.data = this.combinePartialAnswers(id, answers, true);
    }

    return this.navigate();
  }

  private extend(obj, src) {
    Object.keys(src).forEach((key) => {
      obj[key] = src[key];
    });
    return obj;
  }

  private setTitle(templateId: string) {
    this.surveyService.getTemplate(templateId).then((template) => {
      template.templateLanguages.forEach((templateLanguage) => {
        if (templateLanguage.templateId === template.id && templateLanguage.name === this.language) {
          templateLanguage.templateLanguageValues.forEach((value) => {
            if (value.templateLanguageId === templateLanguage.id) {
              if (isNullOrUndefined(value.headTitle) || value.headTitle.length === 0) {
                switch (this.language) {
                  case 'en':
                    this.titleService.setTitle('Survey');
                    break;
                  case 'fr':
                    this.titleService.setTitle('Questionnaire');
                    break;
                  default:
                    this.titleService.setTitle('Survey');
                }
              } else {
                this.titleService.setTitle(value.headTitle);
              }
            }
          });
        }
      });
    });
  }

  private renewSavingCancellationToken() {
    this.savingCancellationToken.next();
    this.savingCancellationToken.complete();
    this.savingCancellationToken = new Subject();
  }

  private showOverlay() {
    this.sharedService.setPageHeight(document.documentElement.scrollHeight);
    this.sharedService.setShowLoader(true);
  }

  private hideOverlay() {
    this.sharedService.setShowLoader(false);
  }
}
