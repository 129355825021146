import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { SurveyService } from '../_services/survey.service';
import { Survey } from '../_models/survey';
import { ConsoleLoggerService } from '../_services/console-logger.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class SurveyResolver implements Resolve<Survey> {
  constructor(private surveyService: SurveyService, private logger: ConsoleLoggerService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (route.queryParams.code) {
      return this.surveyService.getSurvey(route.queryParams.code).pipe(
        map((res: Survey) => res),
        catchError((httpError, obj) => {
          if (httpError.status === 412 || httpError.status === 409) {
            return of({ errorResult: httpError });
          }
          if (httpError.status === 301) {
            window.location.replace(httpError.error);
            return of({ errorResult: httpError });
          }
        })
      );
    } else {
      return this.surveyService.getSurvey().pipe(
        map((res: Survey) => res),
        catchError((httpError, obj) => {
          if (httpError.status === 412 || httpError.status === 409) {
            return of({ errorResult: httpError });
          }
          if (httpError.status === 301) {
            window.location.replace(httpError.error);
            return of({ errorResult: httpError });
          }
        })
      );
    }
  }
}
