import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { appRoutes } from './routes';
import { SurveyResolver } from './_resolvers/survey.resolver';
import { SurveyComponent } from './survey/survey.component';
import { WINDOW_PROVIDERS } from './_providers/window.provider';
import { ConfigService } from './_services/config.service';
import { AngularWebStorageModule } from 'angular-web-storage';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomMissingTranslationHelper } from './missing-translation-handler';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { ReCaptchaConfigService } from './_services/reCaptchaConfig.service';
import { SurveyService } from './_services/survey.service';
import { RecaptchaComponent } from './recaptcha/recaptcha.component';
import { ReCaptchaOption } from './_models/reCaptchaOption';
import { RefererInterceptor } from 'src/interceptors/referer.interceptor';

let recaptchaConfig: ReCaptchaConfigService;

export function initialize(config: ConfigService, httpClient: HttpClient): () => Promise<boolean> {
  return (): Promise<boolean> => {
    return new Promise<boolean>((resolve: (a: boolean) => void): void => {
      config.init.then(() => {
        const surveyService = new SurveyService(httpClient, config);
        recaptchaConfig = new ReCaptchaConfigService(surveyService);

        recaptchaConfig.init().then(() => {
          resolve(true);
        });
      });
    });
  };
}

export function getRecaptchaOption(): ReCaptchaOption {
  const option = recaptchaConfig.config;
  return option;
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, SurveyComponent, LoadingSpinnerComponent, RecaptchaComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
    }),
    AngularWebStorageModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    TranslateModule.forRoot({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHelper },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      multi: true,
      deps: [ConfigService, HttpClient]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefererInterceptor,
      multi: true
    },
    {
      provide: ReCaptchaOption,
      useFactory: getRecaptchaOption,
      deps: [ConfigService, HttpClient]
    },
    SurveyResolver,
    WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
