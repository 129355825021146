import { FormAnswer } from './form-answer';
export interface SurveyAnswer {
  id: string;
  surveyId: string;
  state: SurveyState;
  answersJson: FormAnswer[] | any;
  timezoneOffset: number;
  answerLanguage: string;
  pageDisplayed: boolean;
}

export enum SurveyState {
  InProgress = 0,
  Completed = 1
}
