export enum LogLevel {
  none = 0,
  info = 1,
  warning = 2,
  verbose = 3
}

export interface Config {
  readonly logging: LoggingConfig;

  readonly api: ApiConfig;
}

export interface LoggingConfig {
  readonly level: LogLevel;
}

export interface ApiConfig {
  readonly baseUrl: string;
  readonly modules: ModulesConfig;
}

export interface ModulesConfig {
  readonly voc: VocModuleConfig;
}

export interface ModuleConfigBase {
  readonly path: string;
}

export interface VocModuleConfig extends ModuleConfigBase {}
