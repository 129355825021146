import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { WINDOW } from 'src/app/_providers/window.provider';

@Injectable()
export class RefererInterceptor implements HttpInterceptor {
    constructor(@Inject(WINDOW) private window: Window) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const location = this.window.location;
        const referer = `${location.protocol}//${location.host}${location.pathname}`;

        const authReq = req.clone({
            setHeaders: {
                'x-hexa-referer': referer
            }
        });
        return next.handle(authReq);
    }
}
