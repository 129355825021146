import { Routes, Resolve } from '@angular/router';
import { SurveyResolver } from './_resolvers/survey.resolver';
import { AppComponent } from './app.component';
import { SurveyComponent } from './survey/survey.component';
import { BrandingInfoResolver } from './_resolvers/brandingInfo.resolver';

export const appRoutes: Routes = [
  { path: ':path', component: SurveyComponent, runGuardsAndResolvers: 'always', resolve: { survey: SurveyResolver, branding: BrandingInfoResolver } },
  { path: '', component: SurveyComponent, runGuardsAndResolvers: 'always', resolve: { survey: SurveyResolver, branding: BrandingInfoResolver } },
  { path: '**', component: SurveyComponent, runGuardsAndResolvers: 'always', resolve: { survey: SurveyResolver, branding: BrandingInfoResolver } }
];
