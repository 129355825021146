import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { SurveyService } from '../_services/survey.service';
import { ConsoleLoggerService } from '../_services/console-logger.service';
import { BrandingInfo } from '../_models/brandingInfo';
import { LanguageService } from '../_services/language.service';
import { SessionStorageService } from 'angular-web-storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrandingInfoResolver implements Resolve<BrandingInfo> {
  private languageKey = 'languageKey';

  constructor(private surveyService: SurveyService, private languageService: LanguageService, private session: SessionStorageService, private logger: ConsoleLoggerService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    // THE CODE WAS REMOVED TO HAVE THE MULTI LANGUAGE WORKING
    return null;
  }
}
