// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientEndPoint: '/client',
  pageEndPoint: '/pages',
  formEndPoint: '/forms',
  validateReceiptEndPoint: '/validateReceipt',
  receiptEndPoint: '/receipt',
  mappingsEndPoint: '/mappings',
  surveyAnswerEndPoint: '/surveyAnswer',
  surveyEndPoint: '/surveys',
  latestVersionEndPoint: '/latest-version',
  branchesEndPoint: '/branches',
  partialAnswerKey: 'LanlaPortal:PartialAnswer:Id',
  validationEndPoint: '/validation',
  brandingEndPoint: '/branding',
  unsubscribeEndPoint: '/unsubscribe',
  templatesEndPoint: '/templates',
  reCaptchaEndPoint: '/recaptcha',
  pageDisplayedEndPoint: '/pageDisplayed',
  socialMediaEndPoint: '/socialmedia',
  assemblyVersionEndPoint: "/assemblyVersion"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
